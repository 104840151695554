<template>
  <div>
    <PeriodFilters @apply="applyFilters" />
    <div
      class="main-content rounded-md mt-2"
      :class="!isLoading && isEmpty(events) ? 'border-0' : 'border-0'"
    >
      <CalendarActionsWrapper
        :calendar-date="filters.filterBy === 'Week' ? dateRange : dateRange.startDate"
        :filter-view="filters.filterBy"
        :filters="['Month', 'Week', 'Day']"
        @selectDate="setDate"
        @selectDateRange="setDateRange"
        @setFilter="setFilter"
        @applyFilter="applyFilter"
      ></CalendarActionsWrapper>
      <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
      <div v-else class="mt-2">
        <UiCalendar
          v-if="filters.filterBy === 'Month'"
          :key="forceReRender"
          :calendar-date="dateRange.startDate"
          class="bg-primary-white"
          :events="events"
        >
          <template v-slot="{ events }">
            <div v-for="(event, index) in events" :key="index">
              <div
                v-if="index < 3"
                class="time-schedule flex cursor-pointer justify-center items-center text_size whitespace-nowrap gap-0.5 text-xs"
                @click="singleRecord(event)"
              >
                <p
                  class="flex-shrink-0 dot dot_size h-2 w-2 rounded-full"
                  :style="{ backgroundColor: event.subject.color }"
                ></p>
                <div
                  class="text-white px-1 mb-0.5 rounded-sm"
                  :style="
                    calculatePresentRate(event.attendanceMeta.present, event.attendanceMeta.absent)
                  "
                >
                  {{ `${localTime(event.startTime, true)} - ${localTime(event.endTime, true)}` }}
                </div>
              </div>
            </div>
            <div v-if="events.length > 3" class="time-schedule viewMore">
              <p
                class="font-roboto font-medium text-primary-grey-light cursor-pointer viewMoreText"
                @click="clickDay(events[0].date)"
              >
                {{ $t(`schedule.View More`) + ' ...' }}
              </p>
            </div>
          </template>
        </UiCalendar>
        <ScheduleWeeklyView
          v-if="filters.filterBy === 'Week'"
          :theads="dayRange"
          :table-time="[timeRange[0] || '08:00 am', timeRange[1] || '05:00 pm']"
          :events="events"
          :actions="['edit', 'delete']"
          @viewEvent="viewEvent"
          @editEvent="editEvent"
          @deleteEvent="deleteEvent"
        />
        <DailyView
          v-if="filters.filterBy === 'Day'"
          :key="currentDay"
          :theads="[currentDay]"
          :table-time="[timeRange[0] || '09:00 am', timeRange[1] || '05:00 pm']"
          :events="events"
          @viewEvent="viewEvent"
          @editEvent="editEvent"
          @deleteEvent="deleteEvent"
        />
      </div>
    </div>
    <UIModalContainer
      :modal-show="showModal"
      footer-classes="flex justify-end"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-if="routineData.id" v-i18n="schedule">EDIT_CURRENT_TIME_TABLE</span>
        <span v-else v-i18n="schedule">ADD_CURRENT_TIME_TABLE</span>
      </template>
      <template v-slot>
        <div>
          <ValidationObserver @submit="addNewSchedule">
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="routineData.subject_id"
                title="SUBJECT"
                :options="routineInfo.subjects"
                label="title"
                rules="required"
                reduce="id"
                @change="getTeachersForSubject(routineData.subject_id)"
              />
              <UiSingleSelect
                v-model="routineData.teacher_id"
                title="Teacher"
                :disabled="disabled"
                :options="routineInfo.teachers"
                label="full_name"
                reduce="id"
                :image="true"
              />
            </InputFieldWrapper>
            <InputFieldWrapper class="flex-1">
              <UiDateTimePicker
                v-model="routineData.start_time"
                title="Start time"
                name="Start time"
                type="time"
                class="flex-1"
                rules="required"
              />
              <UiDateTimePicker
                v-model="routineData.end_time"
                title="End time"
                name="End time"
                type="time"
                class="flex-1"
                rules="required"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="routineData.room_id"
                title="Room"
                :options="routineInfo.rooms"
                label="title"
                reduce="id"
              />
            </InputFieldWrapper>

            <button ref="submit" type="submit" class="hidden">Submit</button>
          </ValidationObserver>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="loadingAddNewRoutine" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" @click="$refs.submit.click()">
            <template v-if="loadingAddNewRoutine">
              <Loader></Loader>
            </template>
            <span v-else>
              {{ primaryButtonText }}
            </span>
          </UIButton>
        </div>
      </template>
    </UIModalContainer>

    <UIModalContainer
      footer-classes="flex justify-end"
      :modal-show="routinesDetailModal"
      :modal-width="40"
      @handleClick="handleRoutinesDetailClick"
    >
      <template v-slot:header>
        <span v-i18n="schedule">PER_DETAILS</span>
      </template>
      <template v-slot>
        <div
          v-for="(event, idx) in detailsEventsArr"
          :key="event.id"
          class="pb-8"
          :class="{ 'mb-8': idx === detailsEventsArr.length - 1 }"
        >
          <div class="flex flex-col gap-5 sm:flex-row">
            <span class="text-2xl text-label-text font-bold">{{ event.title }}</span>
            <span class="subjectColor"></span>
          </div>
          <div class="flex flex-row mt-5 gap-3 md:gap-7">
            <div class="flex flex-1 gap-5 flex-col">
              <div class="flex items-center">
                <Icon icon="calender" color="primary-green" height="21" width="18" />
                <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                  {{ event.date || event.day }}
                </span>
              </div>

              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="schedule">Subject</span>
                :
                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ event.subject.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </span>
              </div>
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="title">Lesson</span>
                :
                <span v-if="event.lesson" class="md:px-3 text-primary-grey-light font-normal">
                  {{ event.lesson.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </span>
              </div>
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="schedule">Lesson Type</span>
                :
                <span v-if="event.lesson" class="md:px-3 text-primary-grey-light font-normal">
                  {{ event.lesson.type || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </span>
              </div>
            </div>
            <div class="flex gap-5 flex-1 flex-col text-left">
              <div class="flex clock-icon whitespace-nowrap items-center">
                <Icon icon="clock" color="primary-purple-600" height="21" width="18" />
                <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                  {{ localTime(event.startTime) }} - {{ localTime(event.endTime) }}
                </span>
              </div>
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="schedule">Teacher</span>
                :
                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ event.teacher || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </span>
              </div>
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="schedule">Room</span>
                :
                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ event.room || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </span>
              </div>
            </div>
          </div>
          <hr
            v-if="detailsEventsArr.length > 1 && idx < detailsEventsArr.length - 1"
            class="my-5"
          />
        </div>
      </template>
    </UIModalContainer>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import UiCalendar from '@components/Calendar/index.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiDateTimePicker from '@src/components/UiElements/UIDateTimePicker.vue'
import CalendarActionsWrapper from '@components/Calendar/CalendarActionsWrapper.vue'
import UIModalContainer from '@components/UiElements/UiModalContainer.vue'
import Icon from '@components/icons/icon.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import ScheduleWeeklyView from '@components/UiElements/Scheduler/Schedule.vue'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import DailyView from '@/src/router/views/time-table/current-time-table/PeriodDailyView.vue'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import {
  formatDate,
  getMonthStartAndEndDate,
  formatDateFromLocalToUTC,
  getStartTimeOfTheDay,
  getEndTimeOfTheDay,
} from '@utils/moment.util'
import { objectDeepCopy } from '@/src/utils/generalUtil'
import fileMixin from '@src/mixins/file-mixins'
import PeriodFilters from '@src/router/views/time-table/current-time-table/PeriodFilters.vue'
// Mixin
import timeMixin from '@src/mixins/timeMixin'
import timeTableMixin from '@src/mixins/components/timetable-mixin'
import { getSubjectEventUtc, getTimeRange, getEventsWithNonOverlap } from '@utils/timeTableUtils'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  name: 'Schedule',
  components: {
    PeriodFilters,
    UiSingleSelect,
    Loader,
    ScheduleWeeklyView,
    Icon,
    ValidationObserver,
    CalendarActionsWrapper,
    UIModalContainer,
    DailyView,
    UiCalendar,
    UiDateTimePicker,
    UIButton,
    InputFieldWrapper,
  },
  mixins: [timeMixin, fileMixin, timeTableMixin],
  data() {
    return this.initialState()
  },
  page: {
    title: 'Schedule',
    meta: [
      {
        name: 'description',
        content: ' Automated schedule of class ',
      },
    ],
  },
  computed: {
    primaryButtonText() {
      return this.routineData.id ? 'Update' : 'Save'
    },
    ...mapState({
      activeRole: (state) => state.layout.activeRole,
      currentUser: (state) => state.auth.currentUser,
      showModal: (state) => state.layout.showModal,
      campusId: (state) => state.layout.currentCampusScope.id,
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
    }),
    schedule() {
      return 'schedule'
    },

    ...mapGetters('layout', ['campusTimeZone']),
  },
  watch: {
    events: {
      deep: true,
      immediate: true,
      handler() {
        const temp = getTimeRange(this.events)

        this.timeRange.splice(0, 1, temp[0])
        this.timeRange.splice(1, 1, temp[1])
      },
    },
    showModal: {
      deep: true,
      handler: 'initCreateSchedule',
    },
    currentSectionScope: {
      deep: true,
      handler() {
        if (this.currentSectionScope) {
          this.setDayRange(this.dateRange)
          this.applyFilter()
        } else this.resetComponent()
      },
    },
  },
  mounted() {},
  created() {
    const startDate = new Date()
    const endDate = new Date()

    this.dateRange.startDate = startDate

    endDate.setDate(endDate.getDate() + 6)

    this.dateRange = {
      startDate,
      endDate,
    }
    this.setStaticRightBar()
    if (this.currentSectionScope) {
      this.setDayRange(this.dateRange)
      this.applyFilter()
    }
  },

  beforeUnmount() {
    this.viewEvent({})
  },

  methods: {
    formatDate,
    initialState() {
      return {
        isEmpty,
        GENERAL_CONSTANTS,
        disabled: false,
        isLoading: false,
        title: 'title',
        filtersData: {},
        loadingAddNewRoutine: false,
        forceReRender: 1,
        routineInfo: {
          classes: [],
          subjects: [],
          teachers: [],
          rooms: [],
          days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
        },
        routineData: {
          day: '',
          start_time: null,
          end_time: null,
          room_id: null,
          subject_id: null,
          section_id: '',
          teacher_id: null,
          is_active: true,
        },
        filters: {
          filterBy: 'Month',
        },

        dateRange: {},
        dayRange: ['monday', 'sunday'],
        timeRange: ['0:00 am', '0:00 am'],
        events: [],
        currentDay: 'Monday',
        respDataObjDump: null,
        routinesDetailModal: false,
        detailsEventsArr: [],
      }
    },

    resetComponent() {
      Object.assign(this.$data, this.initialState())
      this.setStaticRightBar()
    },
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.applyFilter()
    },
    setStaticRightBar() {
      this.setRightbarContent({
        header: {
          title: 'PERIOD',
          buttons: [
            {
              title: 'ADD_CURRENT_TIME_TABLE',
              classes: ['primary-button-right'],
              permissions: { roles: ['super_admin', 'campus_admin', 'section_teacher'] },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              { text: 'Total Subject', value: '--' },
              { text: `TODAYS_PERIODS`, value: '--' },
            ],
          },
        ],
      })
    },
    setDateRange(dateRange) {
      this.dateRange = dateRange
    },

    setDate(date) {
      this.dateRange.startDate = date
    },

    setDayRange({ startDate }) {
      const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
      const date = new Date(startDate)

      this.dayRange.splice(0, 1, days[date.getDay()])

      if (date.getDay() + 6 > 6) {
        this.dayRange.splice(1, 1, days[date.getDay() - 1])
      } else {
        this.dayRange.splice(1, 1, days[date.getDay() + 6])
      }
    },

    applyFilter() {
      if (!this.currentSectionScope) return
      this.forceReRender++
      let startDate = formatDateFromLocalToUTC(this.dateRange.startDate)
      let endDate = formatDateFromLocalToUTC(this.dateRange.endDate)
      this.currentDay = formatDateFromLocalToUTC(startDate, 'dddd')
      let startTime = {}
      this.setDayRange(this.dateRange)
      if (this.filters.filterBy === 'Week')
        startTime = {
          [FILTER_KEYS.GREATER_THAN_EQUAL]: `${getStartTimeOfTheDay(startDate)}${
            this.campusTimeZone
          }`,
          [FILTER_KEYS.LESS_THAN_EQUAL]: `${getEndTimeOfTheDay(endDate)}${this.campusTimeZone}`,
        }
      else if (this.filters.filterBy === 'Month') {
        let { startOfMonth, endOfMonth } = getMonthStartAndEndDate(startDate)
        startTime = {
          [FILTER_KEYS.GREATER_THAN_EQUAL]: `${getStartTimeOfTheDay(startOfMonth)}${
            this.campusTimeZone
          }`,
          [FILTER_KEYS.LESS_THAN_EQUAL]: `${getEndTimeOfTheDay(endOfMonth)}${this.campusTimeZone}`,
        }
      } else if (this.filters.filterBy === 'Day')
        startTime = {
          [FILTER_KEYS.GREATER_THAN_EQUAL]: `${getStartTimeOfTheDay(startDate)}${
            this.campusTimeZone
          }`,
          [FILTER_KEYS.LESS_THAN_EQUAL]: `${getEndTimeOfTheDay(startDate)}${this.campusTimeZone}`,
        }
      this.isLoading = true
      let payload = {
        ...{
          $where: {
            ...this.filtersData?.$where,
            started_at: startTime,
          },
        },
      }
      this.filterPeriod(payload)
        .then((resp) => {
          this.fetchPeriodsStats(payload)
          this.events = []
          this.respDataObjDump = resp?.data?.records || []
          if (this.filters.filterBy === 'Month') {
            this.events = resp?.data?.records.map((r) => getSubjectEventUtc(r))
          } else {
            this.events = getEventsWithNonOverlap(resp?.data?.records || [])
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    fetchPeriodsStats(filterBy) {
      this.getPeriodsStats(filterBy).then((response) => {
        this.updateRightbarContent(response?.data)
      })
    },
    updateRightbarContent(data) {
      const subjects = data?.total_subjects_with_detail
      let rightBarSubjectsList = [
        {
          title: this.$t('schedule.Subjects'),
          categories: [
            {
              text: 'Total Subject',
              value: data?.subjects_count,
              tooltipDescription: 'Total No of Subject',
            },
            {
              text: 'TOTAL_PERIODS',
              value: data?.periods_count,
              tooltipDescription: 'Lectures held today',
            },
            {
              text: 'TODAYS_PERIODS',
              value: data?.today_subjects_count,
              tooltipDescription: 'Lectures held today',
            },
          ],
        },
      ]
      subjects?.forEach((subject) => {
        const obj = {
          title: subject.subject_name,
          categories: [
            { text: 'Total Class', value: subject.periods_count },
            { text: `Completed`, value: subject.completed_periods_count },
          ],
          color: subject.subject_color,
        }
        rightBarSubjectsList.push(obj)
      })

      this.setRightbarContent({
        header: {
          title: 'PERIOD',
          buttons: [
            {
              title: 'ADD_CURRENT_TIME_TABLE',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
            },
          ],
        },
        stats: rightBarSubjectsList,
      })
    },

    clickDay(date) {
      this.detailsEventsArr = this.events.filter((single) => single.date === date)
      this.routinesDetailModal = true
    },
    singleRecord(record) {
      this.detailsEventsArr = [record]
      this.routinesDetailModal = true
    },

    setFilter(filter) {
      const startDate = new Date()
      const endDate = new Date()

      this.dateRange.startDate = startDate
      endDate.setDate(endDate.getDate() + 6)

      this.dateRange = {
        startDate,
        endDate,
      }

      this.filters.filterBy = filter
      this.applyFilter()
    },

    handleClick(eventType) {
      if (eventType === 'close') {
        this.closeAddNewUIModalContainer()
      }
    },

    handleRoutinesDetailClick(eventType) {
      if (eventType === 'close') {
        this.routinesDetailModal = false
      }
    },

    viewEvent(eventId) {
      let detailsEventsArr = []
      const event = this.events.find((event) => event.id === eventId)
      if (event) {
        detailsEventsArr.push(event)

        if (event?.isOverlapped) {
          const { overlappedIds } = event || {}
          let allOverlappedEvents = null
          allOverlappedEvents = overlappedIds.map((id) => {
            return getSubjectEventUtc(this.respDataObjDump.find((obj) => obj.id === id))
          })

          detailsEventsArr = [...detailsEventsArr, ...allOverlappedEvents]
        }
        this.detailsEventsArr = detailsEventsArr
        this.routinesDetailModal = true
      }
    },

    editEvent(event) {
      this.getTeachersForSubject(event.subject.subjectId)
        .then(() => {
          this.routineData.id = event?.id
          this.routineData.section_id = event?.section_id
          this.routineData.subject_id = event?.subject?.subjectId
          this.routineData.room_id = event?.room_id
          this.routineData.teacher_id = event?.teacher_id
          this.routineData.day = event?.day
          this.routineData.date = event?.date
          this.routineData.color = event?.color
          this.routineData.start_time = `${event?.date}T${event?.startTime}`
          this.routineData.end_time = `${event?.endDate}T${event?.endTime}`
        })
        .then(() => {
          this.$store.dispatch('layout/setShowModal', true)
        })
    },

    deleteEvent(event) {
      const { id } = event
      this.deletePeriod(id).then(() => {
        this.$store.commit('toast/NEW', {
          type: 'success',
          message: this.$t(`toast.Schedule deleted successfully !`),
        })
        this.applyFilter()
      })

      this.viewEvent({})
    },

    initCreateSchedule(status) {
      if (!this.showModal) this.routineInfo.teachers = []
      if (status) {
        this.getSubjectsRoomsForClass(this.currentSectionScope)
      }
      if (this.activeRole === TENANT_ROLES.SECTION_TEACHER) {
        this.disabled = true
        let user = objectDeepCopy(this.currentUser)
        this.routineInfo.teachers.push(user)
        fullName(user)
        this.routineData.teacher_id = user?.id
      }
    },

    getSubjectsRoomsForClass(currentSectionScope) {
      let teacherPayload
      if (this.activeRole === TENANT_ROLES.SECTION_TEACHER) {
        teacherPayload = {
          ...buildWhereQuery(FILTER_KEYS.IN, 'assigned_teachers_ids', [this.currentUser?.id]),
        }
      }
      return new Promise((resolve, reject) => {
        const query = {
          $where: {
            ...teacherPayload,
            ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', currentSectionScope),
          },
        }
        const subjects = this.classSubjects(query).then((res) => {
          this.routineInfo.subjects = res.data.records
        })
        const rooms = this.getRoomsForClass(query)
        Promise.all([subjects, rooms]).then((values) => {
          this.routineInfo.rooms = values[1].data
          resolve()
        })
      })
    },

    getTeachersForSubject(subject) {
      if (this.activeRole === TENANT_ROLES.SECTION_TEACHER) return
      this.routineData.teacher_id = ''
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', subject),
        },
      }
      return new Promise((resolve, reject) => {
        this.getSectionSubjectTeacher(payload).then((resp) => {
          this.routineInfo.teachers = resp?.data?.records || []
          this.routineInfo.teachers.forEach((teacher) => {
            teacher.full_name = teacher.first_name + ' ' + teacher.last_name
          })
          resolve()
        })
      })
    },

    closeAddNewUIModalContainer() {
      this.clearNewRoutineData()
      this.$store.dispatch('layout/setShowModal', false)
    },

    clearNewRoutineData() {
      this.routineData.day = ''
      this.routineData.section_id = this.currentSectionScope
      this.routineData.subject_id = null
      this.routineData.teacher_id = null
      this.routineData.room_id = null
      this.routineData.color = null
      this.routineData.start_time = null
      this.routineData.end_time = null
      this.routineData.date = new Date()

      if (this.routineData.id) {
        delete this.routineData.id
      }
    },

    addNewSchedule() {
      this.loadingAddNewRoutine = true
      const routineDate = this.routineData.start_time

      const data = {
        routine: {
          day: formatDate(routineDate, 'dddd').toLowerCase(),
          started_at: this.routineData.start_time,
          ended_at: this.routineData.end_time,
          subject_id: this.routineData.subject_id,
          section_id: this.currentSectionScope,
          is_active: true,
          date: routineDate,
        },
        id: this.routineData.id,
      }
      if (this.routineData.teacher_id) data.routine.teacher_id = this.routineData.teacher_id
      if (this.routineData.room_id) data.routine.room_id = this.routineData.room_id

      this.addPeriod(data)
        .then((resp) => {
          this.closeAddNewUIModalContainer()
          this.applyFilter()
        })
        .finally(() => {
          this.loadingAddNewRoutine = false
        })
    },

    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('routine', ['getRoomsForClass']),
    ...mapActions('period', [
      'getSectionSubjectTeacher',
      'addPeriod',
      'deletePeriod',
      'filterPeriod',
      'getPeriodsStats',
    ]),
    ...mapActions('subjects', ['classSubjects']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>

<style lang="scss">
.vs__dropdown-toggle {
  height: inherit;
}

.vs--single.vs--open .vs__selected {
  position: relative !important;
}

.vs__dropdown-menu {
  right: 0 !important;
  left: auto !important;
}
</style>

<style lang="scss">
@import '@src/design/_mediaquery-mixin.scss';
.calenderMobile {
  @include media(null, 640px) {
    .daterangepicker .drp-calendar.right {
      display: none;
    }
  }
}
</style>
