<template>
  <TitleWrapper
    :filter-option="true"
    title="SCHED"
    tooltip-title="SCHED"
    :display-breadcrumb="true"
    :displayed-column-options="csvFilterOptions"
    :already-selected-displayed-column="filtersPayload.displayedColumns"
    :count="filtersCount"
    @cancel="cancelHanlder"
    @applyFilter="emitFiltersPayload($event, invalid)"
    @clear="clearFilters"
  >
    <template v-slot:filterItems>
      <InputFieldWrapper layout-mode="true">
        <UiMultiSelect
          v-if="isAdmin"
          v-model="filtersPayload.teacher_id"
          :options="teacherList"
          title="Teachers"
          label="full_name"
          class="flex-1 mb-5 sm:mb-0"
          :already-selected="selectedTeachers"
          :filter="FILTERS_LIST.TEACHER"
          reduce="id"
          :image="true"
          input-color="bg-white"
          :search-through-api="true"
          @emitQuery="updateQuery"
          @search="loadteacherList"
        />
        <UiSingleSelect
          v-model.trim="filtersPayload.subject_id"
          title="Subject"
          :options="subjectList"
          label="title"
          class="flex-1"
          :class="$style.widthItems"
          reduce="id"
          :filter="FILTERS_LIST.SUBJECT"
          :search-through-api="true"
          @emitQuery="updateQuery"
          @search="getSubjectList"
        />
        <UiSingleSelect
          v-model="filtersPayload.lesson_status"
          title="LESSON_ASSIGNED"
          class="flex-1"
          label="title"
          reduce="value"
          :options="LESSON_STATUS"
          :class="$style.widthItems"
          :filter="FILTERS_LIST.LESSON_STATUS"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model="filtersPayload.teacher_status"
          title="PERIOD_ASSIGNED"
          class="flex-1"
          label="title"
          reduce="value"
          :options="PERIOD_STATUS"
          :class="$style.widthItems"
          :filter="FILTERS_LIST.TEACHER_STATUS"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model="filtersPayload.room_id"
          title="ROOM"
          label="title"
          reduce="id"
          :options="roomsList"
          :class="$style.widthItems"
          class="flex-1"
          :filter="FILTERS_LIST.ROOM"
          :search-through-api="true"
          @emitQuery="updateQuery"
          @search="getRoomsList"
        />
      </InputFieldWrapper>
    </template>
  </TitleWrapper>
</template>

<script>
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { getCountFromObject, removeEmptyKeysFromObject } from '@utils/generalUtil'
import FILTER_KEYS, { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { mapActions, mapState } from 'vuex'
import filterMixins from '@/src/mixins/filter-mixins.js'
import { buildWhereQuery } from '@/src/utils/filters'
import { CHECKED_TYPE } from '@src/constants/attandance/attandance-constant.js'
import { PERIOD_STATUS, LESSON_STATUS } from '@/src/constants/syllabus-constant.js'
import UiMultiSelect from '@/src/components/UiElements/UiMultiSelect.vue'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiMultiSelect,
    UiSingleSelect,
    TitleWrapper,
    InputFieldWrapper,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      CHECKED_TYPE,
      FILTER_KEYS,
      LESSON_STATUS,
      PERIOD_STATUS,
      roomsList: [],
      subjectList: [],
      lessons: [],
      $where: {},
      teacherList: [],
      selectedTeachers: [],
      FILTERS_LIST,
      filtersPayload: {
        subject_id: '',
        room_id: '',
        teacher_status: '',
        lesson_status: '',
        teacher_id: [],
        displayedColumns: [],
      },
      filtersCount: 0,
    }
  },

  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      currentCampusScope: (state) => state.layout.currentCampusScope.id,
      activeRole: (state) => state.layout.activeRole,
    }),
    isAdmin() {
      return [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN].includes(this.activeRole)
    },
  },
  watch: {
    'filtersPayload.subject_id': {
      handler() {
        this.lessons = []
        this.filtersPayload.lesson_id = ''
        this.getLessonList()
      },
    },
    currentSectionScope: {
      handler() {
        this.loadteacherList()
        this.clearFilters()
        this.getSubjectList()
      },
    },
  },
  mounted() {
    this.loadteacherList()
    this.getSubjectList()
    this.getRoomsList()
    this.getLessonList()
    this.updateDisplayedColumnList()
  },
  methods: {
    getLessonList(title = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', title),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', this.filtersPayload.subject_id),
        },
      }
      this.getLessons(payload).then((response) => {
        this.lessons = response?.records || ''
      })
    },
    getRoomsList(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getRooms(payload).then((response) => {
        this.roomsList = response?.records || ''
      })
    },
    getSubjectList(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.classSubjects(payload).then((resp) => {
        this.subjectList = resp?.data?.records || ''
      })
    },
    loadteacherList(query = '') {
      if (!this.isAdmin) return
      let alreadySelectedTeachers = []
      const payload = { $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query) } }
      payload.$where = {
        ...payload.$where,
        ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.currentSectionScope?.id),
        ...buildWhereQuery(FILTER_KEYS.EQUAL, 'campus_id', this.campus?.id),
      }
      removeEmptyKeysFromObject(payload.$where)
      this.getTeacherWithFilters(payload).then((response) => {
        this.teacherList = response?.data?.records
        if (response)
          this.teacherList.forEach((teacher) => {
            if (this.filtersPayload && this.filtersPayload.teacher_id.length) {
              this.filtersPayload.teacher_id.forEach((id) => {
                if (teacher.id === id) alreadySelectedTeachers.push(teacher)
              })
            }
            this.selectedTeachers = alreadySelectedTeachers
          })
      })
    },
    getFiltersCount() {
      this.loadteacherList()
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    ...mapActions('teacher', ['getTeacherWithFilters']),
    ...mapActions('lesson', ['getLessons']),
    ...mapActions('room', ['getRooms']),
    ...mapActions('subjects', ['classSubjects']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
